import { useContext,useState } from "react";

import { HeadImage } from "../MainPage";
import QuoteImg from '../Images/quote.png'
import DataArray from "../DataArray/ClientReview";

const Review = () => {
    const HeadingImage = useContext(HeadImage)

    const [review, setReview] = useState('')
    

    const DispTable = (props) => {
        const [textstyle, setStyle] = useState({
            color:'gray',
            cursor:'pointer'
        })
        const Hover = () => {
            setReview(props.data.Review) 
            setStyle({color:'#00c54d',cursor:'pointer'})
        }
        
        const NonHover =()=>{
            setStyle({color:'gray',cursor:'pointer'})
        }

        return(
        <>
            <div className="row my-4 pe-auto review_text_align " onMouseMove={Hover} onMouseLeave={NonHover} style={textstyle}  >       

                <div className="col-sm-4" >
                    <h4>{props.data.Name}</h4>
                </div>
                <div className="col-sm-4 review_dept"  >
                    <h6 className="my-2">{props.data.Dept}</h6>
                </div>
                <div className="col-sm-4" >
                    {props.data.Stars}
                    <span className="fw-bold fs-5 mx-1">{props.data.Rating}</span>
                </div>  
            </div>
        </>
        )
        
    }
    



    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="row">
                    <div className="col-md-12 text-center z-1">
                        <h2>Check What <em>Clients Say</em> About Our Work</h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-7 wow fadeInLeft order-2 order-lg-1" data-wow-duration="1s" data-wow-delay="0.5s" >
                        {DataArray.map((Data, index) =>{
                            return <DispTable data={Data} key={index}/>
                        })}
                    </div>
                    <div className="BanImage col-md-5 client_review shadow wow fadeInLeft order-1 order-lg-2" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src={QuoteImg} className="mt-5 mx-4 mb-3" alt=""/>
                            <p className="text-white ps-4 pe-4 mb-5 fs-5 fst-italic fw-bold">{review}</p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Review;