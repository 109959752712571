import Footer from "./Footer";
import Navbar from "./Navbar";
import ErrorImg from './Images/404Error.png'
import HomeIcon from './Images/HomeIcon.png'
import HomeHover from './Images/HomeIconHover.png'

import { HashLink } from 'react-router-hash-link';
import { useState , useEffect} from "react";

import WOW from 'wowjs';

const RemovePreloader = () => {
    const preloader = document.getElementById('preloader');

    // Fade out the preloader after a delay
    const fadeOutPreloader = () => {
    preloader.style.opacity = '0';
    setTimeout(() => {
        preloader.style.display = 'none';
    }, 500);
    };

    // Simulating a delay before fading out the preloader
    setTimeout(fadeOutPreloader, 1000);
}


const Errorpage = () => {
    const [Home,setHome] = useState(HomeIcon)

    useEffect(  ()  => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
 
    RemovePreloader()

    const Hover = () => {
      setHome(HomeHover)
    }
    
    const NonHover = () => {
        setHome(HomeIcon)
      
    }
    

    return(
        <>
            <Navbar/>

                <div className="container">
                    <div className="row my-5">
                        <div className="col-md-6 d-flex flex-column justify-content-center order-lg-1 order-2">
                            <h1 className="ErrorFontFamily Fontcolor fw-bold BanImage">Error 404 !!</h1>
                            <h4 className="ErrorFontFamily fw-bold">Ooops!!!', the page you are looking for cannot be found. The requested URL may be incorrect or the page may have been moved, renamed, or deleted.</h4>
                            
                            <HashLink to={'/'} className="d-flex justify-content-center p-2 w-25 rounded-4" onMouseEnter={Hover} onMouseLeave={NonHover}>
                                <input type='image' src={Home} width={50} height={50} name="home" alt="home"/>
                                <strong className="d-flex my-auto ">Home</strong>
                            </HashLink>
                        </div>

                        <div className="col-md-6 order-lg-2 order-1">
                            <div className="text-center about-img wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img className='BanImage w-75' src={ErrorImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>



            <Footer/>
        </>
    )
}

export default Errorpage;
