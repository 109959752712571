import { useContext } from "react";
import { HeadImage } from "../AboutPage";

import HistImg from '../Images/Mission.png'

const Mission = () => {
    const HeadingImage = useContext(HeadImage)
    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id='Contact'>
                <div className="row">
                    <div className="col-md-12 text-center z-1 my-5">
                        <h2>Our <em>Mission</em></h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 BanImage">
                        <img src={HistImg} className="w-100" alt="" />
                    </div>

                    <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
                        <p className="lh-lg Paragraph">
                            Our mission at <strong><i>Tejyash Cyber Solutions</i></strong>  is to simplify technology and empower our clients to thrive in a digital world. We are dedicated to delivering exceptional computer repair services and comprehensive IT solutions that meet the diverse needs of businesses and individuals.
                        </p>
                        <p className="lh-lg Paragraph">
                            We believe in empowering our clients by providing them with the knowledge, tools, and resources necessary to navigate and utilize technology effectively. We strive to bridge the gap between complex technology and our clients' understanding, making it accessible and user-friendly.
                        </p>
                        <p className="lh-lg Paragraph">
                            We understand that technology can be overwhelming and intimidating. Our mission is to simplify technology by breaking it down into manageable and understandable components. We aim to demystify complex concepts and provide clear explanations to our clients, ensuring they feel confident and informed.
                        </p>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Mission;
