import { useContext } from "react";
import { HeadImage } from "../AboutPage";

import HistImg from '../Images/Chooseus.png'

const Chooseus = () => {
    const HeadingImage = useContext(HeadImage)
    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id='Contact'>
                <div className="row">
                    <div className="col-md-12 text-center z-1 my-5">
                        <h2>Why <em>Choose </em>us</h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    
                    <div className="col-md-6 d-flex align-items-center justify-content-center flex-column order-2 order-lg-1">
                        <p className="lh-lg Paragraph">
                            At <strong><i>Tejyash Cyber Solutions</i></strong>, we offer a wide range of services tailored to address the diverse needs of our clients. Our skilled technicians are equipped with the latest tools and knowledge to diagnose and repair hardware and software issues with precision and efficiency. Whether it's a broken screen, a virus-infected system, or a complex network setup, we have the expertise to get your devices up and running smoothly.

                        </p>
                        <p className="lh-lg Paragraph">
                            Additionally, we provide comprehensive IT solutions for businesses, including network setup and maintenance, Websit Development, data backup and recovery, system optimization, cloud computing, and cybersecurity services. Our aim is to streamline your IT infrastructure, enhance productivity, and ensure the security of your digital assets.
                        </p>
                        <p className="lh-lg Paragraph">
                            We understand the importance of getting your devices and systems back in working order as quickly as possible. Our team works efficiently to ensure timely repairs and minimal downtime. Throughout the repair or service process, we maintain clear and open communication with our clients, providing updates and explanations every step of the way. We offer competitive and fair pricing for our services, ensuring that you receive excellent value for your investment.
                        </p>
                    </div>
                    <div className="col-md-6 BanImage d-flex my-auto order-1 order-lg-2">
                        <img src={HistImg} className="w-100" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chooseus;
