import Star from "@material-ui/icons/Star"
import StarHalfIcon from '@material-ui/icons/StarHalf';

const DataArray = [
    {
        Name:'Rushikesh Pandit',
        Dept:'Hardware Devices',
        Rating: 5,
        Stars: <>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <Star/> 
                </>,

        Review:"TejYash Cyber solutions is one of the best company for cyber solutions in Mumbai area. Mr. Tejas is very prompt about their services. They have given me best services for my company's hardware and software issues. Thank you Mr. Tejas for your excellent and prompt services. Also thank you for your suggestions in purchasing the best hardware devices for my company."
    },
    {
        Name:'Huma Khan',
        Dept:'Laptop Repairing',
        Rating: 4.5,
        Stars: <>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <StarHalfIcon/> 
                </>,
        Review:"Well!! It was wonderfull experience with teyjas Infocom and He did repair my laptop which I was thinking willl never work..He did a great Job by giving his genuine customer service and I would really recommend Teyjas Infocom for any laptop related issues. Thank you😊"
    },
    {
        Name:'Aman Wallia',
        Dept:'',
        Rating: 4.7,
        Stars: <>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <StarHalfIcon/> 
                </>,
        Review:"The best place to attend to your IT requirements. Tejas is extremely knowledgeable and an expert in his field... !!"
    },
    {
        Name:'Pratiksha Murukate',
        Dept:'Laptop Repairing',
        Rating: 4.9,
        Stars: <>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <StarHalfIcon/> 
                </>,
        Review:"Laptop is working very nice....Genuineness in work...thank you 😊👍🏼"
    },
    {
        Name:'Raj Barsing',
        Dept:'Software Solution',
        Rating: 4,
        Stars: <>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                </>,
        Review:"Very honest person my laptop was having software issue due to that my battery was not getting charged I had went to other shops they said me that my battery is dead I for new battery they asked me for 4-5k but then I went to Tejyash and within 5 mins he just cleared my software issue and didn't even charged me for that great person 🙌 people please visit here you won't be disappointed."
    },
]
export default DataArray;