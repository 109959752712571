import Carousel from './AboutPage/Carosel';
import Navbar from './Navbar'
import Footer from './Footer'
import History from './AboutPage/History';
import Mission from './AboutPage/Mission'
import Chooseus from './AboutPage/Chooseus'

import HeadingLine from "./Images/heading-line-dec.png"

import Contactbtn from './Contactbtn';

import { createContext,useEffect } from "react"


const HeadImage = createContext();

const RemovePreloader = () => {
    const preloader = document.getElementById('preloader');

    // Fade out the preloader after a delay
    const fadeOutPreloader = () => {
    preloader.style.opacity = '0';
    setTimeout(() => {
        preloader.style.display = 'none';
    }, 500);
    };

    // Simulating a delay before fading out the preloader
    setTimeout(fadeOutPreloader, 1000);
}

const AboutPage =()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    RemovePreloader()

    return (
        <>
            <HeadImage.Provider value={HeadingLine}>
                <Contactbtn/>
                <Navbar/>
                <Carousel/>
                <History/>
                <Mission/>
                <Chooseus/>
                <Footer/>                
            </HeadImage.Provider>
        </>
    )
}

export default AboutPage;
export {HeadImage}