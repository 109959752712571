import React from 'react';

import Img2 from '../Images/Image1.png';
import Img3 from '../Images/Image6.jpg';
import Img1 from '../Images/Image5.jpg';


const Carosel = () => {
  return (
    <>
      {/* <div style={{height:50}}></div> */}
        {/* <div class="parallax"></div> */}
      <div id="carouselExampleAutoplaying" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Img1} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Img2} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Img3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Carosel;
