import { useContext, useState } from "react";
import { HeadImage } from "../MainPage";

import FAQArry from "../DataArray/FAQArray";
import BgImage from "../Images/FAQBg.png"

const FAQ = () => {
    const HeadingImage = useContext(HeadImage)


    const ArrayComp = (props) => {
        // alert (props.Title)

        const [HoverSytle, setHoverStyle] = useState({
            boxShadow: 'rgb(62, 62, 62) 1px 1px 18px',
            
        })

        const Hover = () => {
          setHoverStyle({
            backgroundImage: `url(${BgImage})`,
            backgroundPosition: 'right top',
            backgroundRepeat: 'no repeat',
            backgroundSize: 'cover',
            boxShadow: 'rgb(62 62 62)  -10px 13px 21px',
            right:-10,
            top: -10,
          })
        }
        
        const NonHover = () => {
            setHoverStyle({
                boxShadow: 'rgb(62, 62, 62) 1px 1px 18px',
            })
        }
          

        return(<>
            <div className="col-md-4 col-lg-3 col-sm-6 my-3  " onMouseMove={Hover} onMouseLeave={NonHover} >
                <div className="card faq-style " style={HoverSytle}>
                    <div className="card-body text-center">
                        <h5 className="card-title my-3">{props.Arry.Title}</h5>
                        <p className="faq-Text my-4">{props.Arry.Message}</p>
                    </div>
                </div>
            </div>
        </>);
    }
    


    return(
        <>
            <div className="container FAQ wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id="FAQ">
                <div className="row">
                    <div className="col-md-12 text-center z-1">
                    <h2>Frequrently <em>Asked</em> Quastions</h2>
                    <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            <div className="container wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">

                <div className="row my-5  d-flex justify-content-evenly">


                    {FAQArry.map((ArryData,index)=>{
                        return <ArrayComp Arry= {ArryData} key={index}/>
                    })}


                    
                </div>
            </div>
            
        </>
    )
}

export default FAQ;