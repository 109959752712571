import { useState } from 'react';

import headinLine from '../Images/heading-line-dec.png'
import imagebg from '../Images/service-bg.jpg'

import ServicesArray from '../DataArray/ServiceArray';

const Servicedata = (props) => {
    const [BgStyle,setBgStyle] = useState({
        color:'black',
        backgroundImage: '',
        maxWidth:260,
        borderTopRightRadius:50,
    })

    const [svg, setSvg] = useState(props.Arrdata.Logo)
     
    const ChangeBg =(event)=>{
        event.preventDefault();
        setBgStyle({
            color:'white',
            backgroundImage:`url(${imagebg})`,
            backgroundPosition:'right top',
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            maxWidth:260,
            borderTopRightRadius:50,
        });
        setSvg(props.Arrdata.LogoHov)
    }
    const RechangeBg = (event) => {
        event.preventDefault();
        setBgStyle({
            color:'black',
            backgroundImage: '',
            maxWidth:260,
            borderTopRightRadius:50,
        });
        setSvg(props.Arrdata.Logo)
    }

    return(
        <>
          <div className="card col-lg-3 z-1 mx-2 my-4 shadow wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s" style={BgStyle} onMouseMove={ChangeBg} onMouseLeave={RechangeBg} >
              <div className="card-body">
                <div className="w-25 mt-3 mb-2">
                    <img src={svg} alt="" />
                </div>
                <div className="service-item first-service">
                    <div className="icon"></div>
                    <h4>{props.Arrdata.Heading}</h4>
                    <p className='my-2'> {props.Arrdata.Msg}</p>
                </div>
              </div>
          </div>
      </>
    );
}

const Services =()=>{
    
    
    return(
        <>
            <div className="services" id='Service'>
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12 text-center z-1" >
                        <div className="section-heading   wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            <h2 className='font-weight-bold '>Amazing <em>Services &amp; Features</em> for you</h2>
                            <img src={headinLine} alt=""/>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container my-5 " >
                    <div className="row d-flex justify-content-evenly">
                        {ServicesArray.map((Data, index)=><Servicedata Arrdata = {Data} key={index}></Servicedata>)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Services;