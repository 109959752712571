const FAQArry=[
    {
        Title:'Will the repair be done at my home?',
        Message:"Minor issues like software installtions, part replacement can be done at home. For major repairs involving heavy equipment and work we pickup your Laptop or PC."
    },
    {
        Title:'Is my Laptop safe with you after pick up?',
        Message:"We take ulmost care of your Laptop, ensuring no damages are done and no data is Lost. Whatever is picked up for a repair will be mentioned in our pickup challan."
    },
    {
        Title:'Will I get a warranty on service?',
        Message:"Yes. We give a 30 day service warranty plus the product warranty depending upon the product."
    },
    {
        Title:'What if I only need an inspection?',
        Message:"Our technician will check and provide you details about the problem and a Quotation.If only inspection is done and no other service is availed we charge INR 350 only."
    },
]

export default FAQArry;