
import { useState } from 'react'

import Image1 from './Images/SVG/upArrow.png'
import Image2 from './Images/SVG/UpArrowHov.png'
import Calling1 from './Images/SVG/Calling.png'
import Calling2 from './Images/SVG/CallingHover.png'
import whatsapp from './Images/SVG/Whatsapp.png'
import whatsapp1 from './Images/SVG/Whatsapp1.png'
// import Closebtn from './Images/SVG/closeBtn.png'
// import CloseHov from './Images/SVG/closeBtnHov.png'

// import ModalForm from './RaiseTicket'

// import NewTicket from './RaiseTicket'




const Contactbtn = () => {
    const [Image, setImage] = useState(Image1)
    const [Calling,setCalling] = useState(Calling1) 
    const [Whatsapp,setWhatsapp] = useState(whatsapp) 
    

    const Hover = () => {
        setImage(Image2)
    }
    const NonHover = () => {
        setImage(Image1)
    }

    const HoverCalling = () => {
        setCalling(Calling2)
    }
    const NonHoverCalling = () => {
        setCalling(Calling1)
    }

    const HoverWhatsapp = () => {
        setWhatsapp(whatsapp1)
    }
    const NonHoverWhatsapp = () => {
        setWhatsapp(whatsapp)
    }



   

    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const openModal = () => {
    //     // setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //     // setIsModalOpen(false);
    // };

    return(
        <>
            <div className="position-fixed end-0 bottom-0 p-3 z-2">
                <div className="">
                    <a href="tel:+919867715690">
                        <input type='image' src={Calling} width={35} height={35} name="submit" alt="submit" onMouseEnter={HoverCalling} onMouseLeave={NonHoverCalling}/>
                    </a>
                </div>
                <div className="">
                    <a href="https://api.whatsapp.com/send/?phone=7977060816" target='blank'>
                        <input type='image' src={Whatsapp} width={40} height={40} name="submit" alt="submit" onMouseEnter={HoverWhatsapp} onMouseLeave={NonHoverWhatsapp}/>
                    {/* <ModalForm isOpen={isModalOpen} onRequestClose={closeModal} /> */}
                    </a>
                </div>
                <div className="">
                    <a href="#Navbar">
                        <input type='image' src={Image} width={35} height={35}  name="submit" alt="submit" onMouseEnter={Hover} onMouseLeave={NonHover}/>
                    </a>
                </div>
                
            </div>
                
            
        
        </>
    )    
}

export default Contactbtn;