// import { Facebook,Instagram,Mail } from "@material-ui/icons";
import mailIcon from './Images/mail.png'
import faceIcon from './Images/facebook.png'
import instaIcon from './Images/instagram.png'


const Footer =()=>{
    return(
        <>
            <footer className="footer wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="container bottom-0">
                    <div className="row ">
                            <div className="col-md-6 text-center ">
                                <p ><a href="#Banner" className="text-white">Home | </a> <a className="text-white" href="#Service">Services | </a><a className="text-white" href="#About">About us | </a><a className="text-white" href="#FAQ">FAQ | </a><a className="text-white" href="#Team">Team | </a><a className="text-white" href="#Contact">Contact us</a> </p>
                            </div>
                            <div className="col-md-6">
                                
                                <div className="text-white d-flex justify-content-center">
                                    <h5 className="text-white text-center">Follow Us on:</h5>
                                    <a href="mailto: tejyashcybersolutions@gmail.com" rel="noreferrer" target="_blank" className="text-white"> <input className="mx-2" type='image' src={mailIcon} name="Mail" alt="Mail" width={35} height={35} /></a>
                                    <a href="https://www.facebook.com/profile.php?id=100017573707461" rel="noreferrer" target="_blank" className="text-white"><input className="mx-2" type='image' src={faceIcon} name="facebook" alt="facebook"  width={35} height={35}/></a>
                                    <a href="https://www.instagram.com/tejyashcybersolutions/" rel="noreferrer" target="_blank" className="text-white"><input className="mx-2" type='image' src={instaIcon} name="Instagram" alt="Instagram" width={35} height={35} /></a>
                                </div>
                            </div>
                            <div className="col-md-12 text-center text-white p-3 my-4">
                                <span>Copyright © 2023 TEJYASH CYBER SOLUTION Company. All Rights Reserved.</span>
                            </div>
                    </div>
                </div>
            </footer>

        </>
    );
}

export default Footer;