import { useState } from 'react'
import { HashLink } from 'react-router-hash-link';

import NavLogo from './Images/TYCS3.png'
import Navarray from './DataArray/Navarray'

const Navbar = () => {
    

    const NavElement = (props) => {
        const [Style,setStyle] = useState({
            color:'black'
        })
    
        const Hover = () => {
            setStyle({
                color:"#00c23f",
                textDecoration: 'underline',
            })
        }

        const NonHover = () => {
            setStyle({
                color:"black"
            })
        }

        return(
            <>
                <li className="nav-item ">
                    <HashLink to={props.data.link} className="nav-link" style={Style}  onMouseEnter={Hover} onMouseLeave={NonHover} >{props.data.name}</HashLink>
                </li>
            </>
        )
        
    }
    

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {setIsNavCollapsed(!isNavCollapsed);}

    return (
    <>
    
        <nav className="navbar navbar-expand-lg navbar-white bg-white  shadow z-5 bg-transparent wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id='Navbar'>
            <div className="container z-1">
                <HashLink className='LogoImage z-1' to={'/'}>
                    <img className="w-100 " src={NavLogo} alt=""></img>
                </HashLink>
                
                <button className="navbar-toggler bg-white bg-gradient" type="button"  onClick={handleNavCollapse}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarid">

                    <ul className="navbar-nav ms-auto">
                        
                        {Navarray.map((value,index)=>{
                            return(
                                <NavElement data={value} key={index}/>
                            );
                        })}

                        
                    </ul>
                </div>
            </div>    
        </nav>
    
    </>
    );
    
}

export default Navbar;




