import userLogo from '../Images/UserImage.png'
import kiran from '../Images/TeamImage/kiran.jpg'
import tejas from '../Images/TeamImage/Tejas.png'
// import upasana from '../Images/TeamImage/upasana.jpg'

const Arry = [
    {
        Image: kiran,
        Position:'Manager',
        Education:'Bachelor of Engineering in Electronics',
        Name:'Kiran Relekar',
        Text:'Kiran have more than 5 years of experience in Electronic Field. Experties in Electronic hardware repairing and Computer assembly.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/relekarkiran",
        Insta:"https://www.instagram.com/relekarkiran/",
        LinkIn:"https://www.linkedin.com/in/kiran-relekar-559bbb144/",
    },
    {
        Image: tejas,
        Position:'Founder',
        Education:'Bachelor of Engineering in IT',
        Name:'Tejas Relekar',
        Text:'Tejas have more than 10 years of experience in IT Field. Experties in IOT devices, IT security, IT services & Hardware.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
        Insta:"https://www.instagram.com/tejyashcybersolutions/",
        LinkIn:"https://www.linkedin.com/in/tejas-relekar-a444527b/",
    },
    {
        Image: userLogo,
        Position:'Manager',
        Education:'Bachelor of Engineering in EXTC',
        Name:'Sagar Tukrul',
        Text:'Sagar have more than 5 years of experience in Embedded & Software Field. Experties in Embedded hardware & Software Development.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/sagarsureahtukrul.sagar",
        Insta:"https://www.instagram.com/sagartuk/" ,
        LinkIn:"https://www.linkedin.com/in/sagar-tukrul-9abb67188/",
    },
    // {
    //     Image: upasana,
    //     Position:'Back Office',
    //     Education:'T.Y.B.A.',
    //     Name:'Upasana',
    //     Text:'Upasna have more than 6 years of experience in Back office department.',
    //     Mail:"mailto: tejyashcybersolutions@gmail.com",
    //     Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
    //     Insta:"https://www.instagram.com/tejyashcybersolutions/",
    //     LinkIn:"",
    // },
    {
        Image: userLogo,
        Position:'Engineer',
        Education:'',
        Name:'Zubair',
        Text:'zubair have more than 2 years of experience in Computer & Laptop Repairing.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
        Insta:"https://www.instagram.com/tejyashcybersolutions/",
        LinkIn:"",
    },

]

export default Arry;