import { useContext, useState } from "react";

import { HeadImage } from "../MainPage";

import mailIcon from '../Images/mail1.png'
import faceIcon from '../Images/facebook.png'
import instaIcon from '../Images/instagram.png'
import linkedIcon from '../Images/linkedin.png'
import Arry from '../DataArray/TeamArray'



const TeamArr = (props) => {
    const [cardStyle, setCardStyle] = useState({
        maxWidth:'80%', 
        height:550, 
        boxShadow: 'rgb(62, 62, 62) 1px 0px 12px',
        
    })

    const Hover = () => {
      setCardStyle({
        maxWidth:' 80%',
        height: 550,
        boxShadow: 'rgb(62, 62, 62) -11px 14px 25px',
        top: -15,
        right: -15,

      },)
    }
    const NonHover = () => {
        setCardStyle({
          maxWidth:'80%', 
          height:550, 
          boxShadow: 'rgb(62, 62, 62) 1px 0px 12px',
          
        })
      }
    
    return(
        <>
            <div className="col-md-6 col-lg-4 col-sm-12 d-flex my-5 justify-content-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s" onMouseMove={Hover} onMouseLeave={NonHover} >
                <div className="card rounded-5 team_card" style={cardStyle}>
                    <div className="card-body text-center">
                        <h4 className="card-title text-center text-white">{props.data.Position}</h4>
                        <img className="rounded-circle card-img-top w-50 my-2 " src={props.data.Image} alt=""/>
                        <h4 className="my-3 text-primary">{props.data.Name}</h4>
                        <h5>{props.data.Education}</h5>
                        <div style={{maxHeight: 209, overflow: 'auto', marginTop:-20}}>
                            <p className="team-dscrp ms-4 me-4 lh-lg my-2 text-justify " >{props.data.Text}</p>
                        </div>
                        <div className="container d-flex justify-content-center">
                            <div className="position-absolute bottom-0 mb-1 ">
                                <a href={props.data.Mail} rel="noreferrer" target="_blank">
                                    <input className="mx-1 " type='image' src={mailIcon} name="facebook" alt="facebook"  width={30} height={30}/>
                                </a>
                                <a href={props.data.Insta} rel="noreferrer" target="_blank">
                                    <input className="mx-1" type='image' src={instaIcon} name="facebook" alt="facebook"  width={30} height={30}/>
                                </a>
                                <a href={props.data.Facebook} rel="noreferrer" target="_blank">
                                    <input className="mx-1" type='image' src={faceIcon} name="facebook" alt="facebook"  width={30} height={30}/>
                                </a>
                                <a href={props.data.LinkIn} rel="noreferrer" target="_blank">
                                    <input className="mx-1" type='image' src={linkedIcon} name="facebook" alt="facebook"  width={30} height={30}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const Team = () => {
    const HeadingImage = useContext(HeadImage)

    return(
        <>

            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id="Team">
                <div className="row">
                    <div className="col-md-12 text-center z-1 ">
                        <h2>Our <em>Dedicated & Experienced Team</em> Members</h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            <div className="container  ">
                <div className="row d-flex justify-content-center">

                    {Arry.map((Data, index) => {
                        return <TeamArr data={Data} key={index}/>
                    })}



                    {/* <div className="col-md-6 col-lg-4 col-sm-12 d-flex  justify-content-center "  >
                        <div className="card rounded-5 team_card" style={{maxWidth:'85%', height:"100%"}}>
                            <div className="card-body text-center">
                                <h4 className="card-title text-center text-white">Manager</h4>
                                <img className="rounded-circle card-img-top w-50 my-2" src={userLogo} alt=""/>
                                <h4 className="my-3 text-primary">Kiran Relekar</h4>
                                <h5>Bachelor of Engineering in Electronics</h5>
                                <p className="team-dscrp ms-4 me-4 lh-lg my-2 text-justify">He have more than 5 years of experience in Electronic Field. Experties in
                                Electronic hardware repairing and Computer assembly.</p>
                                <div className="text-primary ">
                                    <Mail className="fs-3 mx-1"/>
                                    <Instagram className="fs-3 mx-1"/>
                                    <Facebook className="fs-3 mx-1"/>
                                    <LinkedIn className="fs-3 mx-1"/>
                                </div>
                                
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>

            
        
        </>
    );
}

export default Team;
