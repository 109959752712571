import BannerImg from '../Images/25087-ai.png'

const Banner =()=>{
    

    return(
        <>
            {/* <div className="main-banner"> */}
                <div className="container " id='Banner'>
                    <div className="row main-banner">
                        <div className="col-lg-6 my-auto order-lg-1 order-2  z-1 banner_text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s" >
                            <h1 className='banner_textcolor ' >Do you have any kind of Laptop or Computer problems?</h1>
                            <p className='banner_para text-wrap banner_textcolor fs-5 my-4'>We'll fix your laptop, your desktop, your Mac or your PC.
                            With our skills and experience, we're the ones you can trust, you'll see.
                            We'll replace your parts, upgrade your software, and optimize your speed,
                            So you can enjoy your computer, and not feel the need.</p>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft order-lg-2 order-1" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img className="BanImage w-100 h-100" src={BannerImg} alt="" />
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </>
    );
}

export default Banner;