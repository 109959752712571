import Banner from "./MainPage/Banner";
import Services from "./MainPage/Services";
import About from "./MainPage/About";
import Client from "./MainPage/Client";
import FAQ from "./MainPage/Faq";
import Review from "./MainPage/Review";
import Team from "./MainPage/Team";
import Contact from "./MainPage/Contact";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Contactbtn from "./Contactbtn";

import HeadingLine from "./Images/heading-line-dec.png"
import FavIcon from './Images/favion.png'

import { createContext,useEffect } from "react";
import WOW from 'wowjs';
import Favicon from 'react-favicon';

// import Preloader from "./Preloader";

const HeadImage = createContext();

const RemovePreloader = () => {
    const preloader = document.getElementById('preloader');

    // Fade out the preloader after a delay
    const fadeOutPreloader = () => {
    preloader.style.opacity = '0';
    setTimeout(() => {
        preloader.style.display = 'none';
    }, 500);
    };

    // Simulating a delay before fading out the preloader
    setTimeout(fadeOutPreloader, 1000);
}


const Main = () => {
    useEffect(  ()  => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
 
    RemovePreloader()
    
    

    
    return(
        <>
            <Favicon url={FavIcon}/>
            <HeadImage.Provider value={HeadingLine}>
                {/* <Preloader/> */}
                
                <Contactbtn/>
                <Navbar/>
                <Banner/>                
                <Services/>
                <About/>
                <Client/>
                <FAQ/>
                <Review/>
                <Team/>
                <Contact/>
                <Footer/>
            </HeadImage.Provider>
        </>
    );
}


export default Main;
export {HeadImage}