import CSale from '../Images/SVG/ComputerSale.svg'
import CSaleHov from '../Images/SVG/ComputerSaleHover.svg'
import CRepaire from '../Images/SVG/Reparing.svg'
import CRepairHov from '../Images/SVG/ReparingHover.svg'
import Refurb from '../Images/SVG/Refurbish.svg'
import RefurbHov from '../Images/SVG/RefurbishHover.svg'
import Websit from '../Images/SVG/website.svg'
import WebsitHov from '../Images/SVG/websiteHover.svg'
import Support from '../Images/SVG/Support.svg'
import SupportHov from '../Images/SVG/SupportHover.svg'

import Virus from '../Images/SVG/Antivirus.svg'
import VirusHov from '../Images/SVG/AntivirusHover.svg'
import Networking from '../Images/SVG/Networking.svg'
import NetworkingHov from '../Images/SVG/NetworkingHover.svg'
import CCTV from '../Images/SVG/cctv.svg'
import CCTVHov from '../Images/SVG/cctvHover.svg'
import Data from '../Images/SVG/DataBackup.svg'
import DataHov from '../Images/SVG/DataBackupHover.svg'
import Digital from '../Images/SVG/DigitalMarketing.svg'
import DigitalHov from '../Images/SVG/DigitalMarketingHover.svg'
import IT from '../Images/SVG/consulting.svg'
import ITHov from '../Images/SVG/consultingHover.svg'


const ServicesArray =[
    {
        Heading:'Computer & Laptop Sales',
        Msg:'We have a wide range of computers and laptops available at discounted prices, with something to suit every budget and need.',
        Logo:CSale,
        LogoHov:CSaleHov,
    },
    {
        Heading:'Computer & Laptop Repairing',
        Msg:'Our team of experienced technicians is here to help diagnose and repair any issues you may be experiencing with your computer or laptop.',
        Logo:CRepaire,
        LogoHov:CRepairHov,
    },
    {
        Heading:'Refurbish Laptops',
        Msg:'Refurbished laptops are a great way to get a high-quality device at a more affordable price. Our refurbished laptops have been carefully inspected, tested, and restored.',
        Logo:Refurb,
        LogoHov:RefurbHov,
    },
    {
        Heading:'Website Development',
        Msg:'We offer a range of website development services, including website design, development, and maintenance. Our process begins with a consultation to understand your requirements and goals, so we can create a customized plan that meets your needs.',
        Logo:Websit,
        LogoHov:WebsitHov,
    },
    {
        Heading:'Remote Desk Support',
        Msg:'Our remote desk support service allows our skilled technicians to connect to your computer or laptop from our office, so we can diagnose and fix issues.',
        Logo:Support,
        LogoHov:SupportHov,
    },
    {
        Heading:'Virus Removal',
        Msg:'We understand the frustration and potential harm that viruses can cause to your computer or laptop. Our team of experienced technicians is here to help you quickly and effectively remove any viruses from your device.',
        Logo:Virus,
        LogoHov:VirusHov,
    },
    {
        Heading:'Networking',
        Msg:'Our networking service is designed to help you connect all of your devices and systems, so you can work efficiently and effectively. We offer a range of networking services, including installation, configuration, and maintenance.',
        Logo:Networking,
        LogoHov:NetworkingHov,
    },
    {
        Heading:'CCTV Installation & Service',
        Msg:'CCTV installation service is designed to help you secure your property and assets, by installing high-quality, reliable CCTV cameras and systems.',
        Logo:CCTV,
        LogoHov:CCTVHov,
    },
    {
        Heading:'Data Backup & Recovery',
        Msg:'Our data backup and recovery service is designed to help you protect your valuable data, and recover it in the event of data loss or corruption.',
        Logo:Data,
        LogoHov:DataHov,
    },
    {
        Heading:'Digital marketing',
        Msg:'We offer a range of digital marketing services, including search engine optimization (SEO), social media marketing and content marketing. Our team of experienced marketers will work with you to create a customized marketing plan that meets your business goals and budget.',
        Logo:Digital,
        LogoHov:DigitalHov,
    },
    {
        Heading:'IT Consultation',
        Msg:'Our team of experienced IT consultants will work with you to assess your current technology infrastructure and processes, identify areas for improvement, and provide customized solutions that meet your specific needs and goals.',
        Logo:IT,
        LogoHov:ITHov,
    },
]

export default ServicesArray;