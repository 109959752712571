import {useNavigate} from 'react-router-dom'

import aboutImg from '../Images/about-right-dec.png'

const About = () => {

    // const CallAPI = async () => {
    //     try{

    //         const ret = await (await fetch('http://localhost:5000/members')).json()
    //         console.log(ret)
    //     }
    //     catch (error){
    //         console.log(error)
    //     }
    // }
    
    const navigate = useNavigate()

    return(
        <>

                <div className="container about-us" id="About">
                    <div className="row ">
                        <div className="col-md-6 align-self-center col-sm-12 z-1 wow fadeInLeft order-2 order-lg-1" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="w-100">

                                <div className="text-center ">
                                    <h4 className="fs-2 ">What We Do<em>  &amp; </em> Who We Are</h4>
                                </div>
                                <div className="text-center ">
                                    <img src="assets/images/heading-line-dec.png" alt="" className="mx-auto" />
                                </div>

                                <div className="text-center my-2">
                                    <h6>Welcome to our TEJYASH CYBER SOLUTIONS</h6>
                                </div>
                                <div className="mb-3 p-2 " >
                                    <p className="">Our company was created with the mission to help businesses and individuals protect themselves against
                                    cyber threats and make the most of modern technology.</p>
                                    <p>We are a team of experienced cybersecurity experts, IT consultants, and technology professionals who are
                                    passionate about helping our clients leverage technology to achieve their business goals while staying
                                    safe and secure in the digital world.</p>
                                    <p>services include a wide range of cybersecurity solutions, such as network security, data backup and
                                    recovery, virus removal, and digital forensics. We also provide technology consultation services to help
                                    our clients make informed decisions about their technology investments and improve their workflows and
                                    productivity.</p>
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="shadow btn btn-success bg-gradient mb-5 " type="submit" onClick={()=>{return navigate('/about')}}>Read more</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 z-1 irder-1 order-lg-2">
                            <div className="text-center about-img wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img className='BanImage w-100' src={aboutImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default About;