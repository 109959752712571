import { useContext, useState } from "react";
import { HeadImage } from "../MainPage";

import ClientList from "../DataArray/ClientArray";

const Client =()=>{
    const HeadingImage= useContext(HeadImage);

    

    const ClientCards = (props) => {
        let CardStyle = {
            backgroundImage: `url(${props.ClientData.Images})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition: 'center',
            minHeight:150,
            backgroundSize: `${props.ClientData.BgSize}`,
        }

        const [BoxStyle, setBoxStyle] =useState({
            maxWidth:300,
            boxShadow: 'rgb(62, 62, 62) 1px 1px 3px',
            
        })

        const NonHover =()=>{
            setBoxStyle({
                maxWidth:300,
                boxShadow: 'rgb(62, 62, 62) 1px 1px 3px',
            })
        }
        const Hover =()=>{
            setBoxStyle({
                maxWidth:300,
                boxShadow: 'rgb(62 62 62) -6px 8px 9px',
                
                right:-10,
                top: -10,

            })
        }
        // 
        return(<>
            <div className="card col-md-4 col-lg-4 col-sm-6 z-1 mx-2 my-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s"  onMouseMove={Hover}  onMouseLeave={NonHover} style={BoxStyle}>
                <a href={props.ClientData.Link} rel="noreferrer" target="_blank" className="card-link" style={CardStyle}> </a>
            </div>
        </>);
    }
    
  

    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="row">
                    <div className="col-md-12 text-center z-1">
                        <h2 className='font-weight-bold '>Our <em>Happy</em> Clients</h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="row d-flex justify-content-evenly my-5" >
                    
                    {ClientList.map((Clientdata,index) => {
                        return <ClientCards ClientData= {Clientdata} key={index}/>                     
                    })}
                </div>
            </div>
        </>
    );
}

export default Client;