import { useContext } from "react";
import { HeadImage } from "../AboutPage";

import HistImg from '../Images/History.png'

const History = () => {
    const HeadingImage = useContext(HeadImage)
    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id='Contact'>
                <div className="row">
                    <div className="col-md-12 text-center z-1 my-5">
                        <h2>Our <em>History</em></h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center order-2 order-lg-1">
                        <p className="lh-lg Paragraph my-2">
                            At <strong><i>Tejyash Cyber Solutions</i></strong>, we have a rich history in the world of technology. It all began 15 years ago, when our founder, <strong><i>Tejas Relekar</i></strong>, recognized the growing need for high-quality computer & Laptop repair services and innovative Cyber solutions. With their extensive expertise and a vision to provide exceptional customer service, they laid the foundation for Tejyash Cyber Solutions company. Since then, we have been dedicated to serving our clients with integrity, professionalism, and technical excellence.
                        </p>
                    </div>
                    <div className="col-md-6 BanImage order-1 order-lg-2">
                        <img src={HistImg} className="w-100" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default History;
