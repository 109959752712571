const NavArray = [
    {
        link: "/", 
        name: "Home", 
    },
    {
        link: "#Service", 
        name: "Services", 
    },
    {
        link: "#About", 
        name: "About Us", 
    },
    {
        link: "#Team", 
        name: "Team", 
    },
    {
        link: "#Contact", 
        name: "Contact Us", 
    },

];

export default NavArray;