import React from 'react';
import ReactDOM from 'react-dom/client';
import {  BrowserRouter,  Routes,  Route } from "react-router-dom";

import './index.css';

import Main from './Components/MainPage';
import About from './Components/AboutPage';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Errorpage from './Components/ErrorPage';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Main/>}/>
      <Route exact path='/about' element={<About/>}/>
      <Route path='*' element={<Errorpage/>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// const express = require('express');
// const path = require('E:/New/tj/tycs/tycs/public');
// const app = express();

// // Serve static files from the build folder
// app.use(express.static(path.join(__dirname, 'build')));

// // Handle all other routes by serving the index.html file
// app.get('*', (req, res) => {
//   res.sendFile(path.join(__dirname, 'build', 'index.html'));
// });

// // Start the server
// app.listen(3000, () => {
//   console.log('Server is running on port 3000');
// });
